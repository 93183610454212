.hero-sidebar {
  --heading-height: 536px;

  position: relative;

  width: 100%;
  height: var(--heading-height);
}
@media (--mobile) {
  .hero-sidebar {
    height: unset;
    margin-top: 370px;
  }
}

.hero-sidebar__image-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--heading-height);
  overflow: hidden;

  background-color: var(--grey);
}
@media (--mobile) {
  .hero-sidebar__image-container {
    height: 370px;
  }
}

.hero-sidebar__image-container::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-image: var(--background-image);
  background-position: var(--background-position, center center);
  background-size: cover;
  filter: blur(50px);
}

.hero-sidebar__image {
  position: relative;
}

.hero-sidebar img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.hero-sidebar .max-width {
  height: 100%;
}
@media (--mobile) {
  .hero-sidebar .max-width {
    padding: 0;
  }
}

.hero-sidebar__sidebar {
  --offset-top: 62px;

  position: absolute;
  top: calc(100% - var(--offset-top));
  left: var(--page-left-padding);
  z-index: 1;

  width: var(--sidebar-width);
  padding: 24px;

  background-color: var(--white);
}
@media (--mobile) {
  .hero-sidebar__sidebar {
    position: unset;

    width: 100%;
    padding: 24px var(--page-right-padding) 0 var(--page-left-padding);
  }
}

.hero-sidebar__title {
  font-family: var(--font-family-title);
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
}
* + .hero-sidebar__title {
  margin-top: 12px;
}

.hero-sidebar__sidebar__details {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}
* + .hero-sidebar__sidebar__details {
  margin-top: 16px;
}

.hero-sidebar__sidebar__details b {
  font-weight: 600;
}

.hero-sidebar__sidebar__details > * + p {
  margin-top: 16px;
}

.hero-sidebar__sidebar__details a,
.hero-sidebar__sidebar__bottom a {
  color: var(--black);
}

* + .hero-sidebar__sidebar__artists {
  margin-top: 24px;
}

.hero-sidebar__sidebar__artists a {
  display: block;

  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  transition: color 200ms;
}
.hero-sidebar__sidebar__artists * + a {
  margin-top: 4px;
}
.hero-sidebar__sidebar__artists a:hover {
  color: rgba(var(--black-rgb), 0.7);
}

* + .hero-sidebar__sidebar__bottom {
  margin-top: 32px;
}

@media (--mobile) {
  .hero-sidebar__sidebar__bottom {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .hero-sidebar__sidebar__bottom .language-switcher {
    margin-top: 0;
  }
}
