.image-text {
  --max-width: calc(
    var(--page-max-width) + var(--page-left-padding) + var(--page-right-padding)
  );
  --horizontal-line-size: 26px;
  --horizontal-line-margin: 40px;

  width: 100%;
  overflow: hidden;
}
@media (--wider-than-mobile) {
  .hero-sidebar + .image-text--reverse.image-text--has-top-content {
    margin-top: calc(var(--hero-sidebar-height) + var(--spacing-48));
  }
}
@media (--mobile) {
  .image-text {
    --horizontal-line-margin: 24px;
  }
}

.layout__rest .image-text:first-child,
* + .image-text {
  margin-top: var(--spacing-48);
}

.image-text .image-text__wrap {
  width: 100%;
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-right: var(--page-right-padding);
  padding-left: var(--page-left-padding);
}
.image-text--reverse .image-text__wrap {
  flex-direction: row-reverse;
}
.image-text--image-width-page .image-text__wrap {
  display: grid;
  grid-template-columns: 1fr var(--sidebar-width);
  grid-gap: var(--sidebar-gap);
}
.image-text--image-width-page.image-text--reverse .image-text__wrap {
  grid-template-columns: var(--sidebar-width) 1fr;
}
@media (--mobile) {
  .image-text--image-width-full .image-text__wrap {
    flex-direction: column;
  }
  .image-text--image-width-page .image-text__wrap {
    display: block;
  }
}

.image-text__image {
  position: relative;

  display: block;
}
.image-text--image-width-full .image-text__image {
  width: calc(100% - var(--sidebar-width) - var(--sidebar-gap));
  margin-right: var(--sidebar-gap);
}
.image-text--image-width-full.image-text--reverse .image-text__image {
  margin-right: 0;
  margin-left: var(--sidebar-gap);
}
.image-text--image-width-page .image-text__image {
  max-width: var(--text-content-max-width);
}
.image-text--image-width-page.image-text--reverse .image-text__image {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}
@media (--mobile) {
  .image-text--image-width-full .image-text__image {
    width: 100%;
    margin-right: 0;
  }
  .image-text--image-width-full.image-text--reverse .image-text__image {
    margin-left: 0;
  }
}

.image-text--image-width-page .image-text__image__wrap {
  flex: 1;
}
.image-text--image-width-full .image-text__image__wrap {
  width: calc(
    ((100vw - 100% - var(--sidebar-width) - var(--sidebar-gap)) / 2) + 100%
  );
  height: 100%;
}
.image-text--image-width-full.image-text--reverse .image-text__image__wrap {
  right: auto;
  left: 0;
}
@media (--mobile) {
  .image-text--image-width-full .image-text__image__wrap {
    width: 100%;
    height: auto;
  }
}

.image-text__image__wrap img {
  display: block;
  width: 100%;
  height: auto;

  background-color: var(--grey);
}

@media (--mobile) {
  .image-text__image {
    width: 100%;
    margin: 0 !important;
  }

  .image-text__image__wrap {
    width: 100%;
  }
}

.image-text__text {
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
}
.image-text--image-width-page.image-text--reverse .image-text__text {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
@media (--wider-than-mobile) {
  .image-text--reverse .image-text__text {
    padding-left: 24px;
  }
}
@media (--mobile) {
  .image-text__text {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-top: var(--horizontal-line-margin);
  }
}

.image-text__text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}
.image-text__text * + p {
  margin-top: 16px;
}
.image-text__text__bottom__hr + p {
  margin-top: var(--horizontal-line-margin);
}

.image-text__text p b {
  font-weight: 600;
}

.image-text__text p a {
  color: var(--black);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.12em;

  transition: 200ms color;
}
.image-text__text p a:hover {
  color: rgba(var(--black-rgb), 0.7);
}

@media (--mobile) {
  .image-text__text__top {
    width: 100%;
    padding: 24px;

    background-color: var(--grey);
  }
  .image-text--has-bottom-content .image-text__text__top {
    margin-top: 32px;
  }
}

.image-text__text__top__hr,
.image-text__text__bottom__hr {
  width: var(--horizontal-line-size);
  margin-top: 40px;

  border: none;
  border-top: 1px solid var(--black);
}
@media (--mobile) {
  .image-text__text__bottom__hr {
    margin-top: 0;
  }
}

.image-text__text__top__hr {
  display: none;
}
@media (--mobile) {
  .image-text__text__top__hr {
    display: block;
  }
}

.image-text__text__bottom {
  margin-top: auto;
}
* + .image-text__text__bottom {
  padding-top: var(--horizontal-line-margin);
}
@media (--mobile) {
  * + .image-text__text__bottom {
    margin-top: 0;
    padding-top: 0;
  }
}
