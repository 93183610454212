.select-input {
  position: relative;

  user-select: none;
}

.select-input__top__label {
  position: relative;
  top: 1px;

  font-family: var(--font-family-text);
  font-weight: 400;
  line-height: 1.3;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;

  outline: none !important;
}

.select-input__options {
  --height-offset: -32px;

  position: absolute;
  top: 35px;
  right: 0;
  z-index: var(--z-index-dropdown);

  display: none;
  text-align: right;
  max-height: calc(var(--max-height, 100%) + var(--height-offset));
  overflow: auto;

  list-style: none;
  background-color: var(--white);
  border: 1px solid #ccc;
}
@media (--mobile) {
  .select-input__options {
    --height-offset: 0px;

    border-left: none;
    border-right: none;
  }
}

.select-input__option {
  padding: 8px 24px;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.5);
  white-space: nowrap;
  text-transform: uppercase;

  background-color: var(--white);
  cursor: pointer;
  transition: color 200ms;
  transition: color 200ms, background-color 200ms;
}
.select-input__option:hover {
  background-color: var(--grey);
}
.select-input__option:first-of-type {
  margin-top: 16px;
}
.select-input__option:last-of-type {
  margin-bottom: 16px;
}
.select-input__option:hover,
.select-input__option--is-highlighted {
  color: var(--black-rgb);
  background-color: var(--grey);
}

.select-input--is-open .select-input__options {
  display: block;
}

@media (--mobile) {
  .select-input__options {
    width: 100vw;
    right: calc(-1 * var(--page-right-padding));
  }
}
