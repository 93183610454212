.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing-48);
  padding-right: var(--page-right-padding);
  padding-bottom: var(--spacing-48);
  padding-left: var(--page-left-padding);
}
.main .loading:first-child {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}
