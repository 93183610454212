.hero-left,
.hero-left__background,
.hero-left__gradient,
.hero-left__wrap {
  height: calc(100vh - var(--bottom-offset));
  height: calc(100svh - var(--bottom-offset));
  min-height: 500px;
  max-height: 800px;
}

.hero-left {
  --icon-size: 16px;
  --bottom-offset: 140px;
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --gradient-color-rgb: var(--black-rgb);

  position: relative;

  overflow: hidden;
  padding-top: var(--header-height);
}
.hero-left--theme-dark {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --gradient-color-rgb: var(--white-rgb);
}
@media (--mobile) {
  .hero-left {
    --bottom-offset: 100px;
  }
}

.hero-left__background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  width: 100%;
  object-fit: cover;

  background-color: var(--black);
  user-select: none;
  /* transform: translateY(calc(-0.4 * var(--js-scroll-top))); */
}

.hero-left__background--is-mobile {
  display: none;
}

@media (--mobile) {
  .hero-left__background--is-mobile {
    display: block;
  }

  .hero-left__background--is-desktop {
    display: none;
  }
}

.hero-left--theme-dark .hero-left__background {
  background-color: var(--grey);
}

.hero-left__gradient {
  --top-linear-gradient: linear-gradient(
    180deg,
    rgba(var(--black-rgb), 0.3) 0%,
    rgba(var(--black-rgb), 0) 40%
  );
  --radial-gradient: radial-gradient(
    45% 45% at 20% 90%,
    rgba(var(--gradient-color-rgb), 0.9) 0%,
    rgba(var(--gradient-color-rgb), 0) 100%
  );

  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  background-image: var(--radial-gradient), var(--top-linear-gradient);
}
@media (--mobile) {
  .hero-left__gradient {
    --radial-gradient: radial-gradient(
      100% 50% at 35% 90%,
      rgba(var(--gradient-color-rgb), 0.7) 0%,
      rgba(var(--gradient-color-rgb), 0) 100%
    );
  }
}

.hero-left__wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 56px;
}
@media (--mobile) {
  .hero-left__wrap {
    padding-bottom: 32px;
  }
}

.hero-left__content {
  z-index: var(--z-index-content);

  display: inline-block;

  color: var(--color);
}
@media (--mobile) {
  .hero-left__content {
    width: 100%;
  }
}

.hero-left__content__title {
  max-width: 22ch;

  font-family: var(--font-family-title);
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
}
* + .hero-left__content__title {
  margin-top: 16px;
}

.hero-left__content__title a {
  color: var(--color);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--color-rgb), 0);

  transition: text-decoration-color 400ms;
}
.hero-left__content:hover .hero-left__content__title a {
  text-decoration-color: rgba(var(--color-rgb), 1);

  transition: text-decoration-color 200ms;
}

.hero-left__content__title a::before {
  --offset: -100px;

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);

  content: '';

  border-radius: 999px;
}

.hero-left__content__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;

  color: rgba(var(--color-rgb), 0.8);
}
* + .hero-left__content__description {
  margin-top: 16px;
}

.hero-left__content__description * + p {
  margin-top: 16px;
}

.hero-left__global-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
