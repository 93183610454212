.list-exhibitions-item {
  --col1: 0.6;
  --col2: 0.4;
  --gap: 48px;
  --height: 450px;

  position: relative;

  display: flex;
  max-width: var(--page-max-width);
  max-width: calc(
    var(--page-max-width) + var(--page-left-padding) + var(--page-right-padding)
  );
  margin-left: auto;
  margin-right: auto;
}
* + .list-exhibitions-item {
  margin-top: var(--spacing-72);
}
@media (--mobile) {
  .list-exhibitions-item {
    --gap: 24px;
    --height: 250px;

    display: block;
  }
}

.list-exhibitions-item--direction-reverse {
  flex-direction: row-reverse;
}

.list-exhibitions-item__image {
  position: relative;

  display: block;
  width: calc((var(--col1) * 100%) - (var(--gap) / 2));
  height: var(--height);
}
@media (--mobile) {
  .list-exhibitions-item__image {
    width: 100%;
    height: 0;
    padding-bottom: 64%;
  }
}

.list-exhibitions-item__image__wrap {
  position: absolute;
  top: 0;
  bottom: 0;

  width: calc(
    (100vw - var(--page-max-width)) / 2 + var(--page-max-width) * var(--col1) -
      (var(--gap) / 2)
  );
  max-width: 900px;
  height: 100%;
}
.list-exhibitions-item--direction-normal .list-exhibitions-item__image__wrap {
  right: 0;
}
.list-exhibitions-item--direction-reverse .list-exhibitions-item__image__wrap {
  left: 0;
}
@media (--page-max-width) {
  .list-exhibitions-item__image__wrap {
    width: 100%;
  }
}
@media (--mobile) {
  .list-exhibitions-item__image__wrap {
    position: static;
  }
}

.list-exhibitions-item__image__wrap img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: var(--grey);
}

.list-exhibitions-item__content {
  display: block;
  width: calc((var(--col2) * 100%) - (var(--gap) / 2));
}
.list-exhibitions-item--direction-normal .list-exhibitions-item__content {
  padding-right: var(--page-right-padding);
}
.list-exhibitions-item--direction-reverse .list-exhibitions-item__content {
  padding-left: var(--page-left-padding);
}
@media (--mobile) {
  .list-exhibitions-item__content {
    width: 100%;
    margin-top: var(--gap);
    padding-right: var(--page-right-padding);
    padding-left: var(--page-left-padding);
  }
}

.list-exhibitions-item--direction-normal .list-exhibitions-item__content {
  margin-left: var(--gap);
  padding-right: var(--page-right-padding);
}
.list-exhibitions-item--direction-reverse .list-exhibitions-item__content {
  margin-right: var(--gap);
}

.list-exhibitions-item--direction-reverse
  .list-exhibitions-item__content:nth-child(odd) {
  padding-left: var(--page-left-padding);
}

@media (--mobile) {
  .list-exhibitions-item--direction-normal .list-exhibitions-item__content {
    margin-left: 0;
  }
  .list-exhibitions-item--direction-reverse .list-exhibitions-item__content {
    margin-right: 0;
  }
}

.list-exhibitions-item__content__name {
  display: block;

  text-decoration: none;
}
* + .list-exhibitions-item__content__name {
  margin-top: 14px;
}

.list-exhibitions-item__content__name::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.list-exhibitions-item__content__name h3 {
  font-family: var(--font-family-title);
  color: var(--black);
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);

  transition: text-decoration-color 100ms;
}
@media (--mobile) {
  .list-exhibitions-item__content__name h3 {
    font-size: 24px;
  }
}

.list-exhibitions-item__content__name:hover h3 {
  text-decoration-color: rgba(var(--black-rgb), 1);
}

* + .list-exhibitions-item__content .card-details {
  margin-top: 16px;
}

* + .list-exhibitions-item__content__description {
  margin-top: 16px;
}

.list-exhibitions-item__content__description svg {
  transform: translateY(2px);
}

.list-exhibitions-item__content__description svg * {
  fill: var(--black);
}

.list-exhibitions-item__content__description p {
  max-width: 43ch;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}
