.not-found--is-first {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}

.not-found {
  --vertical-padding: 72px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  text-align: center;
  color: var(--black);
  line-height: 1.25;
}
@media (--tablet) {
  .not-found {
    --vertical-padding: 50px;
  }
}
@media (--mobile) {
  .not-found {
    --vertical-padding: 32px;
  }
}

.not-found__sup-title {
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}
@media (--mobile) {
  .not-found__sup-title {
    font-size: 20px;
  }
}

.not-found__title {
  font-family: var(--font-family-title);
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
}
@media (--mobile) {
  .not-found__title {
    font-size: 48px;
  }
}
* + .not-found__title {
  margin-top: var(--spacing-48);
}

.not-found__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
}
@media (--mobile) {
  .not-found__description {
    max-width: 30ch;
    margin-left: auto;
    margin-right: auto;
  }
}
* + .not-found__description {
  margin-top: var(--spacing-48);
}

* + .not-found__link {
  margin-top: 178px;
}
@media (--mobile) {
  * + .not-found__link {
    margin-top: 32px;
  }
}

.not-found__link a {
  display: inline-flex;
  align-items: center;
  color: var(--black);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  text-decoration-line: none;
  text-transform: uppercase;
}

.not-found__link a span {
  text-decoration-line: underline;
}
.not-found__link a * + span {
  margin-left: 8px;
}
