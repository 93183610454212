.list-news-item {
  display: flex;
  position: relative;

  flex-direction: column;
}

.list-news-item--highlight {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
@media (--mobile) {
  .list-news-item--highlight {
    grid-row: 1 / 1;
  }
}

.list-news-item__image {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 62.75%;
}
@media (--mobile) {
  .list-news-item__image {
    padding-bottom: 100%;
  }
}
.list-news-item--highlight .list-news-item__image {
  flex: 1;
  height: auto;
  padding-bottom: 0;
}
@media (--mobile) {
  .list-news-item--highlight .list-news-item__image {
    flex: none;
    height: 400px;
  }
}

.list-news-item__image img {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  background-color: var(--grey);
}

* + .list-news-item__content {
  margin-top: 20px;
}
@media (--mobile) {
  * + .list-news-item__content {
    margin-top: 16px;
  }
}

.list-news-item__title {
  display: block;

  text-decoration: none;
  color: var(--black);
}
* + .list-news-item__title {
  margin-top: 12px;
}

.list-news-item__title::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.list-news-item__title h3 {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);
  transition: text-decoration-color 200ms;
}
.list-news-item__title:hover h3 {
  text-decoration-color: rgba(var(--black-rgb), 1);
}
@media (--mobile) {
  .list-news-item__title h3 {
    font-size: 20px;
  }
}

.list-news-item__description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
}
@media (--mobile) {
  .list-news-item__description {
    display: none;
  }
}
* + .list-news-item__description {
  margin-top: 16px;
}

* + .list-news-item__content .card-details {
  margin-top: 16px;
}
