.filter {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.filter__links {
  --horizontal-gap: 16px;
  --vertical-gap: 6px;

  display: flex;
  flex-wrap: wrap;
  margin: calc(-0.5 * var(--vertical-gap)) calc(-0.5 * var(--horizontal-gap));
}

.filter__links li:not(.filter__link--current) {
  opacity: 0.5;
}

.filter__links li {
  list-style: none;
  margin: calc(0.5 * var(--vertical-gap)) calc(0.5 * var(--horizontal-gap));
}

.filter__links a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: var(--black);
  text-decoration: none;

  outline: none;
  border: none;
  background: none;
}
.filter__select {
  margin-left: auto;
}
* + .filter__select {
  margin-left: 16px;
  text-align: right;
}
