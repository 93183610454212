.card {
  --gap: 24px;
  --image-height: auto;
  --title-size: 32px;

  position: relative;

  display: flex;
  flex-direction: row;
  gap: var(--gap);
}

.card--layout-full {
  --gap: 48px;
  --image-height: 350px;
}
.card--layout-full.card--reverse {
  flex-direction: row-reverse;
}
.card--layout-half {
  --image-height: 224px;
}
.card--layout-third {
  --image-height: 200px;
  --title-size: 24px;

  flex-direction: column;
}
@media (--tablet) {
  .card--layout-full {
    --gap: 24px;
  }
  .card--layout-half {
    flex-direction: column;
  }
}
@media (--mobile) {
  .card {
    flex-direction: column;
  }
  .card--layout-full {
    --title-size: 24px;
  }
  .card--layout-full.card--reverse {
    flex-direction: column;
  }
  .card--layout-full {
    --image-height: 164px;
  }

  .card--layout-half {
    --title-size: 24px;
  }
  .card--layout-third {
    --title-size: 20px;
  }
  .grid--mobile-columns-2 .card--layout-third {
    --image-height: 106px;
  }
}

.card__image {
  position: relative;
  height: var(--image-height);

  background-color: var(--grey);
}
.card--layout-full .card__image {
  flex: 0 0 63%;
  width: auto;
}
.card--layout-half .card__image {
  flex: 0 0 var(--image-height);
  width: auto;
}
.card--layout-third .card__image {
  width: 100%;
}
@media (--tablet) {
  .card--layout-full .card__image {
    flex-basis: 60%;
  }
  .card--layout-half .card__image {
    flex: 0 0 224px;
    width: 100%;
  }
}
@media (--mobile) {
  .card--layout-full .card__image {
    flex: auto;
    width: 100%;
  }
  .card--layout-half .card__image {
    width: 100%;
  }
  .card--layout-third .card__image {
    flex: 0 0 var(--image-height);
    width: 100%;
  }
}

.card__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__content {
  display: flex;
  flex-direction: column;
}

.card__title {
  color: var(--black);
  font-family: var(--font-family-title);
  font-size: var(--title-size);
  font-weight: 700;
  line-height: 1.2;
}

* + .card__title {
  margin-top: 16px;
}

.card__title a {
  color: var(--black);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);

  transition: text-decoration-color 100ms;
}
.card:hover .card__title a {
  text-decoration-color: rgba(var(--black-rgb), 1);
}

.card__title a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.card__details {
  color: rgba(var(--black-rgb), 0.8);
  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
}
* + .card__details {
  margin-top: 8px;
}

.card__link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 24px;
}
.card--layout-half .card__link {
  margin-top: auto;
}

.card__link,
.card__link a {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
}

.card__link a::before {
  content: '';

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (--mobile) {
  .grid--mobile-columns-2 .card__link {
    display: none;
  }
}
