.language-switcher {
  --gap: 16px;

  display: flex;
  align-items: center;
}
* + .language-switcher {
  margin-top: 32px;
}

.language-switcher__alternate {
  color: rgba(var(--black-rgb), 0.65);
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;

  transition: color 200ms;
}
.language-switcher__alternate--current {
  color: var(--black);
}
.language-switcher__alternate:hover {
  color: var(--black);
}

.language-switcher__separator {
  display: block;
  width: 0;
  height: 9px;
  margin-left: var(--gap);
  margin-right: var(--gap);

  border: none;
  border-left: 1px solid var(--black);
}

.language-switcher__current {
  text-decoration-line: underline;
  opacity: 0.5;
}
