.layout__rest .infinite-slider:first-child,
* + .infinite-slider {
  margin-top: 45px;
}

@media (--wider-than-mobile) {
  .hero-sidebar + .layout__rest .infinite-slider:first-child,
  .hero-sidebar + .infinite-slider {
    margin-top: calc(var(--hero-sidebar-height) + var(--spacing-48));
  }
}
@media (--mobile) {
  .layout__rest .infinite-slider:first-child,
  * + .infinite-slider {
    margin-top: 32px;
  }
}

.infinite-slider {
  --height: 430px;

  position: relative;

  height: var(--height);
}
@media (--tablet) {
  .infinite-slider {
    --height: 380px;
  }
}
@media (--mobile) {
  .infinite-slider {
    --height: 174px;
  }
}

.keen-slider {
  transition: opacity 10s ease-in-out;
}

.keen-slider.keen-slider__fade-effect {
  opacity: 0 !important;
  transition: opacity 1s ease-in-out;
}

.keen-slider:not(.keen-slider__fade-effect) {
  transition: opacity 300ms ease-in-out;
}

.infinite-slider .keen-slider {
  width: 100%;
  height: 100%;

  opacity: 0;
}
.infinite-slider--loaded .keen-slider {
  opacity: 1;
}

.keen-slider__slide {
  width: auto !important;
  min-width: fit-content;
  height: 100% !important;
  object-fit: contain;
  transition: opacity 300ms ease-in-out;
  background-color: var(--grey);

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.keen-slider__slide--active {
  cursor: pointer;
}
.keen-slider__slide:not(.keen-slider__slide--active) {
  opacity: 0.5;
}

.infinite-slider__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  pointer-events: none;
}

.infinite-slider__controls.infinite-slider__controls__fade-effect {
  opacity: 0 !important;
  transition: opacity 1s ease-in-out;
}

.infinite-slider__controls:not(.infinite-slider__controls__fade-effect) {
  transition: opacity 300ms ease-in-out;
}

.infinite-slider__button {
  --size: 40px;

  position: absolute;
  top: 50%;

  display: block;
  width: var(--size);
  height: var(--size);
  padding: 12px;
  pointer-events: auto;

  backdrop-filter: blur(6px);
  background-color: rgba(var(--white-rgb), 0.1);
  transform: translateY(-50%);
}
.infinite-slider--dragging .infinite-slider__button {
  pointer-events: none;
}

.infinite-slider__button--previous {
  left: 8%;
}
.infinite-slider__button--next {
  right: 8%;
}

@media (--tablet) {
  .infinite-slider__button--previous {
    left: var(--page-left-padding);
  }
  .infinite-slider__button--next {
    right: var(--page-right-padding);
  }
}

.infinite-slider__photo-swipe .pswp__button--arrow {
  --size: 40px;

  display: block;
  width: var(--size);
  height: var(--size);
  padding: 12px;
  pointer-events: auto;

  backdrop-filter: blur(6px);

  transition: background-color 100ms !important;
}
.infinite-slider__photo-swipe .pswp__button--arrow:focus,
.infinite-slider__photo-swipe .pswp__button--arrow {
  background: transparent;
  background-color: rgba(var(--white-rgb), 0.3);
}
.infinite-slider__photo-swipe .pswp__button--arrow:hover {
  background: transparent;
  background-color: rgba(var(--white-rgb), 0.4);
}
.infinite-slider__photo-swipe .pswp__button--arrow:active {
  background: transparent;
  background-color: rgba(var(--white-rgb), 0.3);
}

.infinite-slider__photo-swipe .pswp__button--arrow svg {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.infinite-slider__photo-swipe .pswp__button--arrow svg path {
  stroke: var(--white);
}

.infinite-slider__photo-swipe .pswp__button--arrow--prev {
  left: var(--page-left-padding);
}

.infinite-slider__photo-swipe .pswp__button--arrow--next {
  right: var(--page-right-padding);
}

.infinite-slider__photo-swipe .pswp__top-bar {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.infinite-slider__photo-swipe .pswp__counter {
  text-shadow: none;
}

.infinite-slider__photo-swipe .pswp__button--close svg * {
  stroke: white;
}

.infinite-slider__photo-swipe .pswp__button--close {
  margin-top: 2px;
}
