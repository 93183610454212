.mobile-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-mobile-menu);

  -webkit-tap-highlight-color: transparent;
}

.mobile-menu {
  --button-height: 48px;

  display: none;

  user-select: none;
}
@media (--header-breakpoint) {
  .mobile-menu {
    display: block;
  }
}

.mobile-menu__button {
  --transition-duration: 300ms;
  --border-radius: 16px;

  position: fixed;
  right: var(--page-right-padding);
  bottom: var(--page-horizontal-padding);
  z-index: var(--z-index-mobile-menu-button);

  display: flex;
  width: 86px;
  height: var(--button-height);

  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: var(--border-radius);
  background: var(--white);
  transition: width var(--transition-duration);
  box-shadow: 0px 2px 6px 0px rgba(16, 25, 61, 0.02),
    0px 6px 15px 0px rgba(16, 25, 61, 0.04),
    0px 12px 28px 0px rgba(16, 25, 61, 0.06),
    0px 23px 50px 0px rgba(16, 25, 61, 0.08),
    0px 44px 94px 0px rgba(16, 25, 61, 0.1),
    0px 100px 225px 0px rgba(16, 25, 61, 0.12);
  -webkit-tap-highlight-color: rgba(var(--black-rgb), 0.1);
}
.mobile-menu--open .mobile-menu__button {
  width: var(--button-height);
}
.mobile-menu__button::before {
  --offset: 16px;
  --offset-border-radius: calc(var(--border-radius) + var(--offset) / 2);

  position: absolute;
  top: calc(-1 * var(--offset));
  right: calc(-1 * var(--page-right-padding));
  bottom: calc(-1 * var(--page-horizontal-padding));
  left: calc(-1 * var(--offset));

  content: '';

  border-radius: var(--offset-border-radius) var(--offset-border-radius) 0
    var(--offset-border-radius);
}

.mobile-menu__button__text {
  display: block;
  height: 24px;

  color: var(--black);
  font-family: var(--font-family-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;

  opacity: 1;
  pointer-events: none;
  transform: scale(1) translateY(2.5px);
  transition: opacity var(--transition-duration),
    transform var(--transition-duration);
}
.mobile-menu--open .mobile-menu__button__text {
  opacity: 0;
  transform: scale(0.7) translateY(2.5px);
}

.mobile-menu__button__cross {
  --size: 14px;

  position: absolute;
  top: 50%;
  left: 50%;

  width: var(--size);
  height: var(--size);

  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-duration),
    transform var(--transition-duration);
  transform: scale(0.5) translate(-50%, -50%);
}
.mobile-menu--open .mobile-menu__button__cross {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.mobile-menu__links {
  --transition-duration: 300ms;

  position: fixed;
  right: var(--page-right-padding);
  bottom: calc(var(--page-horizontal-padding) + var(--button-height) + 16px);
  left: var(--page-left-padding);
  z-index: var(--z-index-mobile-menu);

  padding: 12px 0;

  pointer-events: none;
  opacity: 0;
  transform: scale(0.8) translateY(32px);
  background-color: var(--white);
  border-radius: 16px;
  transform-origin: bottom right;
  transition: opacity 200ms, transform 300ms;
  box-shadow: 0px 0.2px 5px 0px rgba(6, 7, 19, 0.03),
    0px 2px 12px 0px rgba(6, 7, 19, 0.05), 0px 7px 20px 0px rgba(6, 7, 19, 0.06),
    0px 18px 33px 0px rgba(6, 7, 19, 0.07),
    0px 39px 57px 0px rgba(6, 7, 19, 0.09),
    0px 100px 125px 0px rgba(6, 7, 19, 0.12);
}
.mobile-menu--open .mobile-menu__links {
  pointer-events: auto;
  opacity: 1;
  transform: scale(1) translateY(0);
}

.mobile-menu__links ul {
  display: block;
}

.mobile-menu__links ul li {
  display: block;
}

.mobile-menu__link {
  display: block;
  padding: 17px 8px 13px 8px;

  color: var(--black);
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  text-decoration: none;

  -webkit-tap-highlight-color: rgba(var(--black-rgb), 0.06);
}
