.footer {
  position: relative;

  padding-top: var(--spacing-72);

  background-color: var(--white);
}
@media (--header-breakpoint) {
  .footer {
    padding-bottom: 32px;
  }
}

.footer__content {
  --vertical-padding: 56px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--mobile) {
  .footer__content {
    --vertical-padding: 40px;

    display: block;
    text-align: center;
  }
}

.footer__ruler {
  border: none;
  border-top: 1px solid rgba(var(--black-rgb), 0.2);
}

@media (--mobile) {
  * + .footer__content__column {
    margin-top: 40px;
  }
}

.footer__content__column h2 {
  font-size: 14px;
  font-weight: 600;
}

.footer__content__column__logo {
  display: block;
}

.footer__content__column__logo svg {
  width: 130px;
  height: auto;
}

.footer__content__infos {
  margin-top: 6px;

  font-size: 14px;
  line-height: 1.5;
}
@media (--mobile) {
  .footer__content__infos {
    color: rgba(var(--black-rgb), 0.7);
  }
}

.footer__content__infos p a {
  display: inline-block;
  padding-top: 12px;

  color: inherit;
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
}
.footer__content__column__description {
  font-family: var(--font-family-text);
  font-size: 13px;
  font-weight: 300;
  line-height: 1.5;
  color: rgba(var(--black-rgb), 0.7);
}
* + .footer__content__column__description {
  margin-top: 8px;
}

.footer__content__column__description p a {
  position: relative;

  color: inherit;
  font-family: var(--font-family-text);
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
  transition: color 100ms;
}
.footer__content__column__description:hover p a {
  color: var(--black);
}

.footer__content__column__description p a::before {
  --offset: -5px;

  position: absolute;
  top: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  left: var(--offset);

  content: '';
}

* + .footer__content__column__sub-text {
  margin-top: 16px;
}

.footer__content__column__sub-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
}

.footer__legal {
  --vertical-padding: 40px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}

.footer__legal p {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
}
@media (--tablet) {
  .footer__legal p {
    text-align: center;
  }
}
@media (--mobile) {
  .footer__legal p {
    max-width: 40ch;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer__legal p a {
  color: var(--black);
}
