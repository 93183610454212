* + .filter-section__result {
  margin-top: 48px;
}
@media (--mobile) {
  * + .filter-section__result {
    margin-top: 40px;
  }
}

.filter-section__current-upcoming {
  --js-scroll-offset-top: var(--header-height);

  margin-top: var(--spacing-48);
}

.filter-section__past-items {
  --js-scroll-offset-top: var(--header-height);
}

.filter-section__current-upcoming + .filter-section__past-items {
  --js-scroll-offset-top: 0;
}

.filter-section__current-upcoming * + .grid {
  margin-top: 40px;
}

@media (--mobile) {
  * + .filter-section__past-items {
    padding-top: 0;
  }
}

.filter-section__current-upcoming + .filter-section__past-items {
  margin-top: 40px;
}

.filter-section--layout-with-temporality
  .filter-section__current-upcoming
  + .filter-section__past-items {
  margin-top: 40px;
  padding-top: 40px;

  border-top: 1px solid rgba(var(--black-rgb), 0.2);
}
@media (--mobile) {
  .filter-section--layout-with-temporality
    .filter-section__current-upcoming
    + .filter-section__past-items {
    padding-top: 0;

    border-top: none;
  }
}

.filter-section:not(.filter-section--layout-with-temporality)
  *
  + .filter-section__past-items {
  padding-top: 0;
}

.filter-section--layout-no-preview .filter-section__past-items {
  margin-top: 40px;
}
@media (--mobile) {
  .filter-section--layout-no-preview .filter-section__past-items {
    margin-top: 40px;
  }
}

.filter-section__no-result {
  --vertical-padding: 32px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  text-align: center;
  color: rgba(var(--black-rgb), 0.8);
}
* + .filter-section__no-result {
  margin-top: 40px;
}

.filter-section .filter {
  position: relative;

  margin-top: 16px;
  padding-top: 16px;

  border-top: 1px solid rgba(var(--black-rgb), 0.2);
}

@media (--mobile) {
  .filter-section .filter::before {
    content: '';
    position: absolute;
    top: -1px;
    width: var(--page-left-padding);
    left: 100%;
    border-top: 1px solid rgba(var(--black-rgb), 0.2);
    max-width: 0;
    transition: max-width ease-in-out;
    transition-duration: 300ms;
  }

  .filter-section .filter::after {
    content: '';
    position: absolute;
    top: -1px;
    width: var(--page-right-padding);
    right: 100%;
    border-top: 1px solid rgba(var(--black-rgb), 0.2);
    max-width: 0;
    transition: max-width ease-in-out;
    transition-duration: 300ms;
  }

  .filter-section.filter-section--filter-is-open .filter::before {
    max-width: var(--page-left-padding);
  }

  .filter-section.filter-section--filter-is-open .filter::after {
    max-width: var(--page-right-padding);
  }
}
