.card-details__date {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}

.card-details__location,
body .card-details__location a {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}
* + .card-details__location {
  margin-top: 4px;
}

body .card-details__location a {
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  transition: color 100ms;
}
body .card-details__location a:hover {
  color: var(--black);
}
