.section-title {
  --gap: 24px;
  --padding: 32px;
  --bottom-padding-offset: -16px;
  --padding-bottom: calc(var(--padding) + var(--bottom-padding-offset));
}
@media (--mobile) {
  .section-title {
    --gap: 8px;
    --padding: 24px;
    --bottom-padding-offset: -14px;
  }
  .section-title--has-right-part {
    --bottom-padding-offset: 0px;
  }
}

.section-title__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-title--border-top-desktop .section-title__wrap {
  padding-top: var(--padding);

  border-top: 1px solid rgba(var(--black-rgb), 0.2);
}
.section-title--border-bottom-always .section-title__wrap,
.section-title--border-bottom-desktop .section-title__wrap {
  padding-bottom: var(--padding-bottom);

  border-bottom: 1px solid rgba(var(--black-rgb), 0.2);
}
@media (--mobile) {
  .section-title__wrap {
    display: block;
  }
  .section-title--border-top-desktop .section-title__wrap {
    padding-top: 0;

    border-top: none;
  }
  .section-title--border-top-mobile .section-title__wrap {
    padding-top: var(--padding);

    border-top: 1px solid rgba(var(--black-rgb), 0.2);
  }
  .section-title--border-bottom-desktop .section-title__wrap {
    padding-bottom: 0;

    border-bottom: none;
  }
  .section-title--border-bottom-mobile .section-title__wrap {
    padding-bottom: var(--padding-bottom);

    border-bottom: 1px solid rgba(var(--black-rgb), 0.2);
  }
}

.section-title__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-40);
  font-weight: 700;
  line-height: 1.3;
}

* + .section-title__right-part {
  margin-left: var(--gap);
}
@media (--mobile) {
  * + .section-title__right-part {
    margin-left: 0;
    padding-top: var(--gap);
  }
}

.section-title__link {
  --vertical-padding: 12px;

  display: block;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--black);
  font-family: var(--font-family-text);
  font-weight: 500;
  line-height: 1.25;
  text-decoration-line: underline;
  text-underline-offset: 0.18em;
}
@media (--mobile) {
  .section-title__link {
    --vertical-padding: 0;

    font-size: 14px;
  }
}
