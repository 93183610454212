* + .list-artists {
  margin-top: 48px;
}

.list-artists--first {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}

@media (--mobile) {
  * + .list-artists {
    margin-top: 64px;
  }
}

.list-artists__artists {
  --horizontal-gap: 40px;
  --vertical-gap: 40px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--horizontal-gap);
  row-gap: var(--vertical-gap);
}
@media (--mobile) {
  .list-artists__artists {
    --horizontal-gap: 32px;

    grid-template-columns: repeat(2, 1fr);
  }
}
* + .list-artists__artists {
  margin-top: var(--spacing-72);
}
.list-artists--first * + .list-artists__artists {
  margin-top: var(--spacing-48);
}
