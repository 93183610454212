.video-section {
  margin-top: 48px;
}

@media (--wider-than-mobile) {
  .video-section--first {
    min-height: calc(var(--hero-sidebar-height) - var(--spacing-48));
  }
}

.video-section__grid {
  width: 100%;
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  gap: var(--sidebar-gap);
}
.video-section--width-page .video-section__grid {
  display: block;
}
@media (--mobile) {
  .video-section__grid {
    display: block;
  }
}

.video-section__container {
  max-width: var(--text-content-max-width);
}
.video-section--width-page .video-section__container {
  max-width: 100%;
}

.video-section__wrap {
  position: relative;

  padding-top: 56.25%;
  background: var(--grey);
}

.video-section__loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.video-section__player {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
