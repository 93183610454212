/* moneta-sans-light - latin */
@font-face {
  font-family: 'Moneta Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/font-faces/moneta-sans-light.woff2') format('woff2'),
    url('/font-faces/moneta-sans-light.woff') format('woff');
}
/* moneta-sans-regular - latin */
@font-face {
  font-family: 'Moneta Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/font-faces/moneta-sans-regular.woff2') format('woff2'),
    url('/font-faces/moneta-sans-regular.woff') format('woff');
}
/* moneta-sans-bold - latin */
@font-face {
  font-family: 'Moneta Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/font-faces/moneta-sans-bold.woff2') format('woff2'),
    url('/font-faces/moneta-sans-bold.woff') format('woff');
}
/* moneta-sans-black - latin */
@font-face {
  font-family: 'Moneta Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src:
    url('/font-faces/moneta-sans-black.woff2') format('woff2'),
    url('/font-faces/moneta-sans-black.woff') format('woff');
}

/* subjectivity-light - latin */
@font-face {
  font-family: 'Subjectivity';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/font-faces/subjectivity-light.woff2') format('woff2'),
    url('/font-faces/subjectivity-light.woff') format('woff');
}
/* subjectivity-regular - latin */
@font-face {
  font-family: 'Subjectivity';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/font-faces/subjectivity-regular.woff2') format('woff2'),
    url('/font-faces/subjectivity-regular.woff') format('woff');
}
/* subjectivity-medium - latin */
@font-face {
  font-family: 'Subjectivity';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('/font-faces/subjectivity-medium.woff2') format('woff2'),
    url('/font-faces/subjectivity-medium.woff') format('woff');
}
/* subjectivity-bold - latin */
@font-face {
  font-family: 'Subjectivity';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/font-faces/subjectivity-bold.woff2') format('woff2'),
    url('/font-faces/subjectivity-bold.woff') format('woff');
}

/* work-sans-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url('/font-faces/work-sans-v18-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  src: url('/font-faces/work-sans-v18-latin-100italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url('/font-faces/work-sans-v18-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  src: url('/font-faces/work-sans-v18-latin-200italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/font-faces/work-sans-v18-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/font-faces/work-sans-v18-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/font-faces/work-sans-v18-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/font-faces/work-sans-v18-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/font-faces/work-sans-v18-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/font-faces/work-sans-v18-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/font-faces/work-sans-v18-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/font-faces/work-sans-v18-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/font-faces/work-sans-v18-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/font-faces/work-sans-v18-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/font-faces/work-sans-v18-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  src: url('/font-faces/work-sans-v18-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url('/font-faces/work-sans-v18-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  src: url('/font-faces/work-sans-v18-latin-900italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
