.header {
  --color: var(--white);
  --logo-height: 23px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-header);

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--header-height);
  pointer-events: none;
}
.header::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -32px;
  left: 0;

  content: '';
  pointer-events: none;

  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    ease-in-out,
    rgba(0, 0, 0, 0) 100%
  );
}

.header .max-width {
  position: relative;

  display: flex;
  justify-content: space-between;
  height: 100%;
}

.header__logo {
  display: flex;
  align-items: center;
  height: 100%;

  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

.header__logo svg {
  display: block;
  width: auto;
  height: var(--logo-height);
  user-select: none;
}

.header__links {
  --gap: 32px;

  display: flex;
  align-items: center;
}
@media (--header-breakpoint) {
  .header__links {
    display: none;
  }
}

.header__link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: calc(var(--gap) / 2);

  color: var(--color);
  font-family: var(--font-family-title);
  font-size: 21px;
  font-weight: 700;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.3em;
  text-decoration-color: rgba(var(--white-rgb), 0);

  transition: text-decoration-color 200ms;
  pointer-events: auto;
}
.header__link:not(:last-child) {
  padding-right: calc(var(--gap) / 2);
}
.header__link--current,
.header__link:hover {
  text-decoration-color: rgba(var(--white-rgb), 1);
}
