.tag {
  --color: var(--black);
  --color-rgb: var(--black-rgb);

  font-family: var(--font-family-text);
  color: var(--color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.42px;
  text-transform: uppercase;
}
.tag--theme-light {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}

.tag b {
  display: inline-block;
  padding: 1px 2px 0px 2px;

  font-weight: 400;

  border-radius: 2px;
  background-color: rgba(var(--color-rgb), 0.1);
}
.tag--theme-light b {
  background-color: rgba(var(--color-rgb), 0.2);
}
