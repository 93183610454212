.right-content {
  --color: rgba(var(--black-rgb), 0.8);

  color: var(--color);
}
@media (--wider-than-mobile) {
  .right-content--safe-height {
    min-height: calc(var(--hero-sidebar-height) - var(--spacing-48));
  }
}

.layout__rest .right-content:first-child,
* + .right-content {
  margin-top: 48px;
}

.right-content__grid {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  gap: var(--sidebar-gap);
}
@media (--mobile) {
  .right-content__grid {
    display: block;
  }
}

.right-content__grid__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: var(--text-content-max-width);
}

.right-content__grid__container * + p {
  margin-top: 16px;
}

.right-content__grid__container b {
  font-weight: 600;
}

.right-content__grid__container p {
  font-size: 14px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.28px;
}

.right-content__grid__container a {
  color: var(--color);
  text-underline-offset: 0.1em;
}

/* Overwrites custom colors from Storyblok rich text. */
.right-content__grid__container span[style] {
  color: var(--color) !important;
}
