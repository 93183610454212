.all-news--first {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}

* + .all-news__first-section,
.all-news__first-section + .all-news__second-section,
.all-news__second-section * + .grid {
  margin-top: var(--spacing-48);
}

@media (--mobile) {
  .all-news .card__link {
    display: none;
  }
}
