* + .title-section {
  margin-top: var(--spacing-48);
}

.title-section--is-first {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}

.layout__rest .title-section:first-child {
  margin-top: var(--spacing-72);
}
