* + .pagination {
  margin-top: 48px;
}
@media (--mobile) {
  * + .pagination {
    margin-top: 40px;
  }
}

.pagination ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  list-style: none;
}
@media (--mobile) {
  .pagination ul {
    gap: 12px;
  }
}

.pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  min-width: 34px;

  text-decoration: none;
  color: var(--black);
  text-align: center;

  background-color: var(--grey);
  transition: opacity 150ms;
}
.pagination__link:not(.pagination__link--current):hover {
  opacity: 0.6;
}
.pagination__link:active {
  opacity: 1;
}
@media (--mobile) {
  .pagination__link {
    padding: 8px 12px;
  }
}

.pagination__link--current {
  padding-left: 0;
  padding-right: 0;
  min-width: auto;

  background-color: transparent;
  cursor: default;
}
