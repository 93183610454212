.list-artists-item {
  position: relative;

  text-align: center;
}

.list-artists-item__image {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 56.65%;
}

.list-artists-item__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;

  user-select: none;
  background-color: var(--grey);
}

.list-artists-item__name {
  display: block;
  text-decoration: none;
}
* + .list-artists-item__name {
  margin-top: 24px;
}
@media (--mobile) {
  * + .list-artists-item__name {
    margin-top: 16px;
  }
}

.list-artists-item__name::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list-artists-item__name h3 {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--black);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);

  transition: text-decoration-color 100ms;
}
@media (--mobile) {
  .list-artists-item__name h3 {
    font-size: 18px;
  }
}

.list-artists-item__name:hover h3 {
  text-decoration-color: rgba(var(--black-rgb), 1);
}
