* + .list-news {
  margin-top: 72px;
}
@media (--mobile) {
  * + .list-news {
    margin-top: 64px;
  }
}

.list-news__items {
  --horizontal-gap: 40px;
  --vertical-gap: var(--horizontal-gap);

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--horizontal-gap);
  row-gap: var(--vertical-gap);
}
@media (--tablet) {
  .list-news__items {
    --horizontal-gap: 24px;
  }
}
@media (--mobile) {
  .list-news__items {
    --vertical-gap: 40px;

    grid-template-columns: repeat(2, 1fr);
  }
}
* + .list-news__items {
  margin-top: var(--spacing-72);
}
