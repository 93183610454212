.wide-card {
  --image-width: 225px;
  --image-height: 127px;
  --grid-template-columns: var(--image-width) 1fr 0.2fr;
  --gap: 24px;
  --padding: 40px;

  position: relative;

  display: grid;
  grid-template-columns: var(--grid-template-columns);
  gap: var(--gap);
  padding-bottom: var(--padding);
}
@media (--mobile) {
  .wide-card {
    --image-width: 100%;
    --image-height: 106px;
    --gap: 16px;
    --padding: 0;

    display: flex;
    flex-direction: column;
  }
}

* + .wide-card {
  padding-top: var(--padding);
}
@media (--wider-than-mobile) {
  * + .wide-card {
    border-top: 1px solid rgba(var(--black-rgb), 0.2);
  }
}

.wide-card__image {
  position: relative;

  width: 100%;
  height: var(--image-height);
}

.wide-card__image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  background-color: var(--grey);
}

.wide-card__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

* + .wide-card__title {
  margin-top: 16px;
}
@media (--mobile) {
  * + .wide-card__title {
    margin-top: 12px;
  }
}

.wide-card__title a {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  text-decoration-color: rgba(var(--black-rgb), 0);
  color: var(--black);

  transition: text-decoration-color 100ms;
}
.wide-card:hover .wide-card__title a {
  text-decoration-color: rgba(var(--black-rgb), 1);
}
@media (--mobile) {
  .wide-card__title a {
    font-size: 20px;
  }
}

.wide-card__title a::before {
  content: '';

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* + .wide-card__content__text {
  margin-top: 16px;
}
@media (--mobile) {
  * + .wide-card__content__text {
    margin-top: 8px;
  }
}

.wide-card__content__text__free p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: rgba(var(--black-rgb), 0.8);
}

.wide-card__link {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: var(--black);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;
  font-size: 14px;
  font-weight: 500;
}
@media (--mobile) {
  .wide-card__link {
    text-align: left;
  }

  .wide-card--hide-mobile-link .wide-card__link {
    display: none;
  }
}
