.text--first {
  margin-top: calc(var(--header-height) + var(--spacing-48));
}

.text__grid {
  display: grid;
  grid-template-columns: 1fr 70%;
  grid-gap: 24px;
}
@media (--mobile) {
  .text__grid {
    display: block;
  }
}

* + .text__grid {
  margin-top: 48px;
}
@media (--mobile) {
  * + .text__grid {
    margin-top: 10px;
  }
}

.text__content {
  max-width: var(--text-content-max-width);

  font-size: 14px;
  line-height: 1.65;
  letter-spacing: 0.28px;
  color: rgba(var(--black-rgb), 0.8);
}

@media (--mobile) {
  .text__aside {
    padding-top: 24px;

    border-top: 1px solid rgba(var(--black-rgb), 0.2);
  }
}

.text__aside ul {
  --gap: 16px;

  list-style: none;
}

.text__mobile-menu,
.text__aside__mobile-menu-btn {
  display: none;
}

@media (--mobile) {
  .text__aside {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .text__aside::before {
    content: '';
    position: absolute;
    top: -1px;
    width: var(--page-left-padding);
    left: 100%;
    border-top: 1px solid rgba(var(--black-rgb), 0.2);
    max-width: 0;
    transition: max-width ease-in-out;
    transition-duration: var(--mobile-menu-anim-time);
  }

  .text__aside::after {
    content: '';
    position: absolute;
    top: -1px;
    width: var(--page-right-padding);
    right: 100%;
    border-top: 1px solid rgba(var(--black-rgb), 0.2);
    max-width: 0;
    transition: max-width ease-in-out;
    transition-duration: var(--mobile-menu-anim-time);
  }

  .text--mobile-menu-open .text__aside::before {
    max-width: var(--page-left-padding);
  }

  .text--mobile-menu-open .text__aside::after {
    max-width: var(--page-right-padding);
  }

  .text__aside > ul {
    display: none;
  }
  .text__mobile-menu,
  .text__aside__mobile-menu-btn {
    display: block;
  }

  .text__aside__mobile-menu-btn {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
  }

  .text__aside__mobile-menu-btn svg {
    margin-left: 12px;
  }

  .text__mobile-menu {
    margin-top: 28px;
    margin-left: calc(-1 * var(--page-right-padding));
    width: 100vw;
  }

  .text__mobile-menu ul {
    padding: 24px;

    text-align: right;
    list-style: none;

    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .text__mobile-menu ul * + li {
    margin-top: 16px;
  }

  .text__aside__mobile-menu-btn {
    position: relative;
  }

  .text__aside__mobile-menu-btn::after {
    --offset: 26px;
    content: '';
    position: absolute;
    top: calc(-1 * var(--offset));
    left: calc(-1 * var(--offset));
    right: calc(-1 * var(--offset));
    bottom: calc(-1 * var(--offset));
  }
}

.text__aside ul li a,
.text__mobile-menu ul a {
  display: block;

  color: rgba(var(--black-rgb), 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.3em;
  text-decoration-color: rgba(var(--black-rgb), 0);

  transition:
    color 100ms,
    text-decoration-color 100ms;
}
.text__aside ul li:not(:first-child) a {
  padding-top: calc(var(--gap) / 2);
}
.text__aside ul li:not(:last-child) a {
  padding-bottom: calc(var(--gap) / 2);
}
.text__aside ul li a:hover {
  color: var(--black);
  text-decoration-color: rgba(var(--black-rgb), 1);
}

@media (--mobile) {
  .text__aside .language-switcher {
    margin-top: 0;
  }
}

@media (--mobile) {
  * + .text__content {
    margin-top: 32px;
  }
}

.text__content h2 {
  --js-scroll-offset-top: var(--header-height);

  font-weight: 700;
  text-transform: uppercase;
}
.text__content * + h2 {
  margin-top: 16px;
}
.text__content * + p {
  margin-top: 16px;
}

.text__content a {
  color: var(--black);
  text-underline-offset: 0.1em;
}
