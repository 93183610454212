.alphabetical-artists {
  padding-bottom: 48px;
}
* + .alphabetical-artists {
  margin-top: 48px;
}

.alphabetical-artists__content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: rgba(var(--black-rgb), 0.8);
}

* + .alphabetical-artists__content {
  margin-top: 32px;
}

.alphabetical-artists__view-all-btn {
  display: block;
  width: 100%;

  text-align: left;
  color: var(--black);
  font-size: 14px;
  text-transform: uppercase;
  user-select: none;
}
@media (--mobile) {
  .alphabetical-artists__view-all-btn {
    padding-top: 24px;

    border-top: 1px solid rgba(var(--black-rgb), 0.2);
  }
}

.alphabetical-artists__view-all-btn svg {
  margin-left: 4px;

  transition: transform 300ms ease-in-out;
}

.alphabetical-artists--open .alphabetical-artists__view-all-btn svg {
  transform: rotate(180deg);
}

.alphabetical-artists__content-container * + h3 {
  margin-top: 24px;
}

.alphabetical-artists__content-container h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.65;
  letter-spacing: 0.48px;
}

.alphabetical-artists__content-container ul {
  --gap: 8px;

  list-style: none;
  break-inside: avoid-column;
}
.alphabetical-artists__content-container ul li a {
  display: block;

  color: rgba(var(--black-rgb), 0.7);
  text-decoration-line: underline;
  text-decoration-thickness: 0.06em;
  text-underline-offset: 0.15em;

  transition: color 200ms;
}
.alphabetical-artists__content-container ul li:not(:first-child) a {
  padding-top: calc(var(--gap) / 2);
}
.alphabetical-artists__content-container ul li:not(:last-child) a {
  padding-bottom: calc(var(--gap) / 2);
}
.alphabetical-artists__content-container ul li a:hover {
  color: var(--black);
}
