* + .artist-exhibitions {
  margin-top: 48px;
}

.artist-exhibitions {
  overflow: hidden;
}

* + .artist-exhibitions__exhibitions {
  margin-top: 48px;
}

@media (--mobile) {
  .artist-exhibitions__exhibitions * + .wide-card {
    margin-top: 48px;
  }
}
